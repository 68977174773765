body {
  margin: 0;
  font-family: SourceSansPro !important;
}

@font-face {
  font-family: "SourceSansPro";
  src: url('./fonts/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url('./fonts/SourceSansPro-Bold.ttf');
}

@font-face {
  font-family: "SourceSansPro-Semibold";
  src: url('./fonts/SourceSansPro-Semibold.ttf');
}

@font-face {
  font-family: "SourceSansPro-Italic";
  src: url('./fonts/SourceSansPro-Italic.ttf');
}


@font-face {
  font-family: "HelveticaNeue";
  src: url('./fonts/HelveticaNeue.ttf');
}

@font-face {
  font-family: "ltromaticRegular";
  src: url("./fonts/ltromatic.ttf");
}