@import '~bootstrap/scss/functions';

// https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// Color system

$white: #fff;
$gray-100: #f1f1f1;
$gray-150: #d3d8e0;
$gray-200: #e9ecef;
$gray-250: #f2f5fc;
$gray-300: #a9cbd8;
$gray-400: #ced4da;
$gray-500: #bbc4d9;
$gray-600: #a09f9f;
$gray-700: #848c9f;
$gray-750: #42485a;
$gray-800: #3a3f51;
$gray-850: #2d283a;
$gray-870: #252541;
$gray-900: #191919;
$black: #000;

$blue: #007bff;
$indigo: #6122e6;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary-red: #ab181f;
$primary-red-dark: #7f121a;
$primary-red-light: #ed1b24;

$footer-background: #161629;
$badge-background: #e3eaf9;

$loader-overlay-background: rgba(0, 0, 0, 0.5);

$primary: $primary-red;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$header-height: 60px;
$footer-height: 330px;
$header-padding: 27px;

$mobile-footer-height: 710px;

$more-icon-height: 32px;

$option-height: 50px;
$selected-option-color: #f1f1f1;
$dragged-over-option-color: #d3d3d3;

$titles-black: #222629;
$marvel-red: #ab181f;
$marvel-gray: #e3eaf9;
$terms-gray: #757a8e;
$shipping-gray: #f2f5fc;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@mixin checkered-background {
  background-image: linear-gradient(45deg, $gray-300 25%, transparent 25%),
    linear-gradient(-45deg, $gray-300 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, $gray-300 75%),
    linear-gradient(-45deg, transparent 75%, $gray-300 75%);
  background-size: 1rem 1rem;
  background-position: 0 0, 0 0.5rem, 0.5rem -0.5rem, -0.5rem 0;
}

@mixin button {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
}

@mixin button-primary {
  @include button();
  background-color: $primary-red;
  color: $white;

  &:not(:disabled):active {
    background-color: $primary-red-dark;
  }

  //&:not(:disabled):hover {
  //    background-color: $primary-red-dark;
  //}
}

$secondary-button-border-color: #bbc4d9;

@mixin button-secondary {
  @include button();
  box-shadow: none;
  background-color: $white;
  border: 1px solid $secondary-button-border-color;
  color: $gray-800;
}
