@import './theme';

.App {
  /* Путь к файлу со шрифтом */
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.button-container-50 {
  margin-top: 30px;
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.without-margin-button {
  margin-top: 0;
}

a {
  color: #495057;
}

a:hover {
  color: #495057;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn-primary.custom-button {
  box-shadow: none;
  border-radius: 5px;
  text-transform: none;
  height: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 35px;
}

.btn-primary.primary {
  background: $primary-red;
  border-color: $primary-red;
  text-transform: none;
  font-size: 14px;
  box-shadow: none;
}

.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

.btn-primary.gray {
  background: #ffffff;
  border-color: #bbc4d9;
  color: $gray-800;
  text-transform: none;
  font-size: 14px;
  box-shadow: none;
}

.btn-primary.primary:not(:disabled):not(.disabled):active {
  background: $primary-red;
  box-shadow: none;
  @include button-primary();
  text-transform: none;
  border-color: $primary-red;
  font-weight: bold;
}

.btn-primary.gray:not(:disabled):not(.disabled):hover {
  background: $white;
  color: $primary-red;
  box-shadow: none;
}

.btn-primary.gray:not(:disabled):not(.disabled):active {
  background-color: $primary-red;
  color: $white;
  font-weight: bold;
  box-shadow: none;
  border-color: $primary-red;
}

.form-control:focus {
  border-color: #ab181f;
  outline: none;
  box-shadow: 0 0 0 0.02rem #ab181f;
}

.form-control.is-invalid {
  background-image: none;
}

@media screen and (min-width: 426px) and (max-width: 1024px) {

  .btn-primary.primary,
  .gray {
    height: 50px;
    font-size: 18px;
  }

  .btn-primary.primary:not(:disabled):not(.disabled):active {
    font-size: 18px;
  }
}

@media screen and (max-width: 425px) {

  .btn-primary.primary:not(:disabled):not(.disabled):active {
    font-size: 18px;
  }

  .btn-primary.gray {
    height: 60px;
    font-size: 18px;
  }
}