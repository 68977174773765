@import '../../theme';

.content {
    min-height: calc(100vh - #{$footer-height} - #{$header-height});
}

@media screen and (min-width: 1280px) {
    .content {
        min-height: 80vh;
    }
}

@media screen and (min-width: 1360px) {
    .content {
        min-height: 87vh;
    }
}

@media screen and (min-width: 1920px) {
    .content {
        min-height: 80vh;
    }
}

@media screen and (max-width: 425px) {
    .modal-menu-open-content {
        padding-top: $header-height;
    }
}